<template>
  <div>
    <DataTable v-if="created" :options="options" />

    <vx-dialog
      v-model="dialog"
      title="Select page schema"
      width="400"
      actions
      @resolve="$add('page', { id: schemaId })"
    >
      <vx-input
        v-model="schemaId"
        type="autocomplete"
        :items="schemas"
        item-text="name"
        item-value="_id"
        name="Page schema"
        label="Page schema"
        :required="true"
      />
    </vx-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { PageSchemaService } from '@tutti/services';

export default {
  data() {
    return {
      created: false,
      dialog: false,
      schemas: [],
      schemaId: this.$route.params.id,
      options: {
        addNew: this.addNew,
        action: {
          showDuplicate: true,
          showPreview: true,
        },
        actions: [
          {
            text: 'Publish',
            body: { isActive: true },
            message: 'publish',
          },
          {
            text: 'Unpublish',
            body: { isActive: false },
            message: 'unpublish',
          },
          {
            text: 'Delete',
            body: { isDeleted: true },
          },
        ],
        showHeader: {
          publishedBy: true,
          publishedAt: true,
        },
      },
    };
  },
  async created() {
    await this.getSchemas();
    this.options.headers = [
      { text: 'Name', value: 'name', tooltip: true },
      {
        text: 'Schema',
        value: 'schemaId',
        width: '250px',
        component: { name: 'dtView', props: { name: 'page-schema', permission: 'cms_page_schema' } },
        filter: {
          type: 'autocomplete',
          items: this.schemas,
          itemText: 'name',
          itemValue: '_id',
        },
      },
      {
        text: 'Published',
        value: 'isActive',
        width: '50px',
        sortable: false,
        align: 'center',
        component: { name: 'dtCheckbox', props: { action: true } },
      },
      {
        text: 'Sitemap',
        value: 'sitemap',
        width: '120px',
        align: 'center',
        component: { name: 'dtCheckbox', props: { action: true } },
      },
    ];

    this.created = true;
  },
  methods: {
    addNew() {
      if (this.schemaId) {
        this.$add('page', { id: this.schemaId });
      } else {
        this.dialog = true;
      }
    },
    async getSchemas() {
      const response = await PageSchemaService.getAll({ option: { wantPagination: false } });
      if (response && response.data.data.length) {
        this.schemas = _.sortBy(response.data.data, ['obj', 'name']);
      }
    },
  },
};
</script>
